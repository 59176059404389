import store, { INIT } from '@/store'
import Vue from 'vue'
import VueRouter from 'vue-router'
import { delayedAddInteractionTracking } from '~/utils'
import { routes } from './routes'
Vue.use(VueRouter)

const router = new VueRouter({
  routes,
  mode: 'history',
  linkActiveClass: 'is-active'
})

router.beforeEach(async (to, _from, next) => {
  const { isReady } = store.getters

  const userGatewayFeatures = await store.getters['user/userGatewayFeatures']
  if (userGatewayFeatures?.suretyBonds === false && to.meta?.isSuretyBondsEnabled) {
    return next({ name: 'default' })
  }

  if (!isReady) {
    await store.dispatch(INIT)
  }

  const { isAuthenticated } = store.getters

  if (_from.query && _from.query.next) {
    const nextPath = String(_from.query.next)
    if (nextPath && nextPath != to.fullPath) {
      localStorage.setItem('next-path', nextPath)
    }
  }

  if (to.meta?.public) {
    return next()
  }

  if (to.meta?.anonymous && isAuthenticated) {
    return next({ name: 'default' })
  }

  if (!to.meta?.anonymous && !isAuthenticated) {
    if (!to.meta?.isDefaultRoute) {
      localStorage.setItem('next-path', to.fullPath)
    }
    return next({ name: 'account-login' })
  }

  return next()
})

router.afterEach(async (_to, _from) => {
  // add must come after remove and also after the route renders
  delayedAddInteractionTracking()
})

export default router

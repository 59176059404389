import { AppConfig } from '@/config'
import Auth, { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'

export let oauthLoginEnabled = false
export const configure = (config: AppConfig) => {
  oauthLoginEnabled = !!config.cognito.oauth
  Auth.configure({ ...config.cognito })
}

Auth.configure({ storage: sessionStorage })
export const startOauthSignIn = async (provider: CognitoHostedUIIdentityProvider) =>
  await Auth.federatedSignIn({ provider })
export const signIn = async (username: string, password: string, reCaptchaToken?: string) =>
  await Auth.signIn(username, password, reCaptchaToken ? { captcha: reCaptchaToken } : {})
export const signOut = async () => await Auth.signOut()
export const currentSession = async () => await Auth.currentSession()
export const currentUser = async () => await Auth.currentAuthenticatedUser()
export const signUp: any = async (username: string, password: string, reCaptchaToken?: string, attributes?: object) => {
  let error: string | null = null
  await Auth.signUp({
    username,
    password,
    validationData: { captcha: reCaptchaToken },
    attributes
  }).catch(err => {
    error = err
  })
  return error
}
export const forgotPassword = async (username: string) => await Auth.forgotPassword(username)
export const forgotPasswordSubmit = async (username: string, code: string, newPassword: string) =>
  await Auth.forgotPasswordSubmit(username, code, newPassword)
export const confirmSignUp = async (username: string, code: string) => await Auth.confirmSignUp(username, code)
export const resendConfirmationCode = async (username: string) => await Auth.resendSignUp(username)
